













import { Component, Prop, Watch } from 'nuxt-property-decorator';
import Vue from 'vue';
import { AlignementOptions, BlokContentText, TextColumnOptions } from '~/types';

@Component
export default class ContentText extends Vue {
  @Prop() blok!: BlokContentText;
  get columns (): TextColumnOptions {
    return this.blok.columns ? this.blok.columns : '1';
  }
  get alignement (): AlignementOptions {
    return this.blok.alignement ? this.blok.alignement : 'left';
  }

  $refs: { textContainer: HTMLElement };
}
